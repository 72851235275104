import * as React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

const SwapPage = () => (
  <Layout>
    <Seo title="BLUME Swap" />
    <h1>BLUME Swap</h1>
    <p>Content coming soon.</p>
  </Layout>
)

export default SwapPage
